import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Modal from '../modal';
import * as styles from './goal-split-modal.module.scss';
import ProgressBar from '../../progress-bar/progress-bar';

interface GoalSplitModalProps {
  onClose: () => void
  association: any
}

const GoalSplitModal: React.FC<GoalSplitModalProps> = function ({ onClose, association }) {
  const { t } = useI18next();

  const maxValue = Math.max(
    association.goal,
    association.earningSummary.total + association.earningSummary.rejected,
  );

  return (
    <Modal
      hasCloseBtn
      isOpen
      onClose={onClose}
    >
      <div className={styles.container}>
        <h1>
          {t('Association_GoalSplitPopup_Title', { association: association.name })}
        </h1>
        <div className={styles.description}>
          {t('Association_GoalSplitPopup_Description')}
        </div>

        <div>
          <div>
            <ProgressBar
              value={association.earningSummary.total}
              background="#40c0c0"
              valueTitle={t('Association_GoalSplitPopup_Total')}
              total={maxValue}
            />
            <div className={styles.text}>
              {t('Association_GoalSplitPopup_TotalDescription')}
            </div>
          </div>

          <div>
            <div>
              <ProgressBar
                value={association.earningSummary.confirmed}
                background="#f18706"
                valueTitle={t('Association_GoalSplitPopup_Confirmed')}
                total={maxValue}
              />

              <ProgressBar
                value={association.earningSummary.pending}
                background="#aaaaaa"
                valueTitle={t('Association_GoalSplitPopup_Pending')}
                leftOffsetValue={association.earningSummary.accepted}
                total={maxValue}
              />
              <div className={styles.text}>
                {t('Association_GoalSplitPopup_AcceptedDescription')}
              </div>
            </div>
          </div>

          <div>
            <ProgressBar
              value={association.earningSummary.rejected}
              background="#da3f06"
              valueTitle={t('Association_GoalSplitPopup_Rejected')}
              leftOffsetValue={association.earningSummary.accepted
                                         + association.earningSummary.pending}
              total={maxValue}
            />
            <div className={styles.text}>
              {t('Association_GoalSplitPopup_RejectedDescription')}
            </div>
          </div>
        </div>

      </div>
    </Modal>
  );
};

export default GoalSplitModal;
