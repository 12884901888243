import React, { useEffect, useRef, useState } from 'react';
import { currencyFormat } from '../../../utils/currency-utils';
import * as styles from './progress-bar.module.scss';

interface ProgressBarProps {
  value: number
  valueTitle: string
  total: number
  leftOffsetValue?: number
  background: string
}

const ProgressBar: React.FC<ProgressBarProps> = function ({
  value, valueTitle, total, background, leftOffsetValue = 0,
}) {
  const progressElement = useRef<HTMLDivElement | null>(null);

  const calculateWidth = () => {
    if (!progressElement.current) return 0;
    const parentWidth = progressElement.current.offsetWidth;
    return (parentWidth / total) * value;
  };

  const calculateLeftOffset = () => {
    if (!progressElement.current) return 0;
    const parentWidth = progressElement.current.offsetWidth;
    return (parentWidth / total) * leftOffsetValue;
  };

  const recalculateProgressValueStyles = () => ({
    width: `${calculateWidth()}px`,
    left: `${calculateLeftOffset()}px`,
    background,
  });

  const [progressValueStyles, setProgressValueStyles] = useState(recalculateProgressValueStyles());

  useEffect(() => {
    setProgressValueStyles(recalculateProgressValueStyles());
  }, [progressElement.current?.offsetWidth]);

  return (
    <>
      <div className={styles.title}>
        <div>{currencyFormat.format(Number.isNaN(value) ? 0 : value)}</div>
        <div>{valueTitle}</div>
      </div>
      <div ref={progressElement} className={styles.progress}>
        <div
          className={styles.progressValue}
          style={progressValueStyles}
        />
      </div>
    </>
  );
};

export default ProgressBar;
