import React from 'react';
import * as styles from './alert.module.scss';

interface AlertProps {
  message: string;
  onClose: () => void
}

function Alert({ message, onClose }: AlertProps) {
  const handleCloseKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClose();
    }
  };

  return (
    <div
      className={styles.alert}
      role="alert"
    >
      <div
        className={styles.message}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={
        { __html: message }
}
      />
      <span
        className={styles.close}
        onClick={onClose}
        onKeyDown={handleCloseKeyDown}
        role="button"
        tabIndex={0}
        aria-label="close"
      >
        &times;
      </span>
    </div>
  );
}

export default Alert;
