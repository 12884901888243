export const LocalStorageKeys = {
  RedirectToAssociationInfoClosed: 'redirectToAssociationInfoClosed',
};

export class LocalStorageUtils {
  static getItem(key: string) {
    if (typeof document !== 'undefined') {
      return localStorage.getItem(key);
    }
  }

  static setItem(key: string, value: string) {
    if (typeof document !== 'undefined') {
      return localStorage.setItem(key, value);
    }
  }
}
