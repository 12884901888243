// extracted by mini-css-extract-plugin
export var btn = "association-banner-module--btn--0edb8";
export var commission = "association-banner-module--commission--97697";
export var container = "association-banner-module--container--718a4";
export var content = "association-banner-module--content--6b1f2";
export var firstLine = "association-banner-module--first-line--1c73d";
export var goal = "association-banner-module--goal--da963";
export var goalValue = "association-banner-module--goal-value--3fb04";
export var info = "association-banner-module--info--8c03b";
export var left = "association-banner-module--left--165b6";
export var main = "association-banner-module--main--5b178";
export var media = "association-banner-module--media--ddf94";
export var name = "association-banner-module--name--b1871";
export var progressContainer = "association-banner-module--progress-container--f9a8f";
export var progressValue = "association-banner-module--progress-value--6a03a";
export var right = "association-banner-module--right--da9d9";
export var slogan = "association-banner-module--slogan--35350";
export var value = "association-banner-module--value--9fd66";