import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useState } from 'react';
import instagramLogo from '../../../images/social-media/square/instagram.jpg';
import { toCloudinaryUrl } from '../../../utils/cloudinary-utils';
import { currencyFormat } from '../../../utils/currency-utils';
import GoalSplitModal from '../../shared/modal/goal-split-modal/goal-split-modal';
import * as styles from './association-banner.module.scss';

function AssociationBanner({ data }: { data: Queries.AssociationPageQuery }) {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { association } = data;

  if (!association) return null;

  const associationGoal = association.goal ?? 0;
  const associationCommissionSum = association.commissionSum ?? 0;

  const barGoal = associationGoal > associationCommissionSum
    ? associationGoal : associationGoal + associationCommissionSum;
  const goalPercentStyle = {
    width: `${Math.min((associationCommissionSum * 100) / barGoal, 100)}%`,
  };

  function onInfoClick() {
    setIsInfoModalOpen(true);
  }

  const handleInfoKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onInfoClick();
    }
  };

  const { t, language } = useI18next();

  return (
    <>
      {isInfoModalOpen ? <GoalSplitModal onClose={() => { setIsInfoModalOpen(false); }} association={association} /> : null}

      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.left}>
              <img alt="logo" src={association.croppedLogoUrl ? toCloudinaryUrl(association.croppedLogoUrl, 193) : ''} />
            </div>
            <div className={styles.right}>
              <div className={styles.firstLine}>
                <img alt="logo" src={association.croppedLogoUrl ? toCloudinaryUrl(association.croppedLogoUrl, 193) : ''} />
                <div className={styles.name}>{(association.name ?? '').toUpperCase()}</div>
              </div>
              <div className={styles.slogan}>
                {!association!.slogan ? <>{t('Association_BannerDefaultText', { association: association!.name })}</>
                  : <div>{association!.slogan}</div>}
              </div>

              {association!.goal ? (
                <div className={styles.goal}>
                  <div className={styles.commission}>
                    <div>
                      <div className={styles.value}>
                        <div>
                          {currencyFormat.format(associationCommissionSum)}
                        </div>
                        <div
                          className={styles.info}
                          onClick={onInfoClick}
                          onKeyDown={handleInfoKeyDown}
                          role="button"
                          tabIndex={0}
                          aria-label="info"
                        >
                          ?
                        </div>
                      </div>
                    </div>
                    <div className={styles.goalValue}>{currencyFormat.format(barGoal)}</div>
                  </div>
                  <div className={styles.progressContainer}>
                    <div className={styles.progressValue} style={goalPercentStyle} />
                  </div>
                </div>
              )
                : null}
            </div>
          </div>
        </div>

        <div className={styles.media}>
          <a
            href={language === 'fr' ? 'https://www.instagram.com/trooper_belgique/' : 'https://www.instagram.com/trooper_belgie/'}
            target="_blank"
            rel="noreferrer"
          >
            <img alt="ig" src={instagramLogo} />
          </a>
        </div>
      </div>
    </>
  );
}

export default AssociationBanner;
